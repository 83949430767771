import React from "react";
import PageHeader from "../General/PageHeader";
import { SEO } from "../General/SEO";
import { leadershipInformation } from "../../Constants/leadership";
import { convertImageUrlToThumbnail } from "../../Utils/functions";
import ModTrussBolding from "../General/ModTrussBolding";

const Leadership: React.FC = () => {
  return (
    <div>
      <SEO
        title={"Leadership - ModTruss"}
        description={"Learn about our leadership."}
        path={"/leadership"}
      />
      <PageHeader title="Leadership" />
      <div className="py-8 sm:py-12 md:py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Title Section */}
          <div className="text-center mb-8 sm:mb-12 md:mb-16">
            <h2
              className="text-2xl sm:text-3xl md:text-4xl font-bold text-content 
              mb-3 sm:mb-4"
            >
              Our Leadership
            </h2>
            <p
              className="text-base sm:text-lg text-content-secondary max-w-3xl mx-auto 
              px-4 sm:px-6"
            >
              Meet the team driving innovation and excellence in modular
              structural solutions.
            </p>
          </div>

          {/* Leaders Grid */}
          <div
            className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 
            gap-6 sm:gap-8 md:gap-12"
          >
            {leadershipInformation.map((leader) => (
              <div key={leader.id} className="group">
                {/* Image Container */}
                <div
                  className="relative h-80 sm:h-96 overflow-hidden 
                  mb-4 sm:mb-5 md:mb-6"
                >
                  <img
                    src={convertImageUrlToThumbnail(leader.image)}
                    alt={leader.name}
                    className="w-full h-full object-contain"
                  />
                  {/* Social Links Overlay */}
                  {/* <div
                    className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent 
                    opacity-0 group-hover:opacity-100 transition-opacity duration-300 
                      flex items-center justify-center gap-3 sm:gap-4"
                  >
                    {leader.linkedin && (
                      <a
                        href={leader.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-1.5 sm:p-2 bg-white/10 hover:bg-white/20 
                          rounded-full transition-colors"
                      >
                        <svg
                          className="w-5 h-5 sm:w-6 sm:h-6 text-white"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                        </svg>
                      </a>
                    )}
                    {leader.email && (
                      <a
                        href={`mailto:${leader.email}`}
                        className="p-1.5 sm:p-2 bg-white/10 hover:bg-white/20 
                          rounded-full transition-colors"
                      >
                        <svg
                          className="w-5 h-5 sm:w-6 sm:h-6 text-white"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                          />
                        </svg>
                      </a>
                    )}
                  </div> */}
                </div>

                {/* Content */}
                <div>
                  <h3 className="text-xl sm:text-2xl font-bold text-content mb-1">
                    {leader.name}
                  </h3>
                  <p
                    className="text-sm sm:text-base text-content font-medium 
                    mb-3 sm:mb-4"
                  >
                    {leader.title}
                  </p>
                  <p
                    className="text-sm sm:text-base text-content-secondary 
                    leading-relaxed"
                  >
                    <ModTrussBolding text={leader.bio} />
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leadership;
