export interface LeadershipMember {
  id: number;
  name: string;
  title: string;
  image: string;
  bio: string;
  linkedin?: string;
  email?: string;
}

export const leadershipInformation: LeadershipMember[] = [
  {
    id: 1,
    name: "Patrick Santini",
    title: "Owner, Inventor, CEO",
    image: "https://files.modtruss.com/files/patrick_headshot.png",
    bio: "Patrick is the inventor and patent holder of the ModTruss product line. As previous owner and President of Kernwer LLC, Patrick has over 20 years experience in the entertainment production industry. It is this experience and knowledge that lead to the design of ModTruss and its accessories. As CEO Of ModTruss, Patrick will continue to develop and engineer the product line.",
  },
  {
    id: 2,
    name: "Steve Weltin",
    title: "CFO",
    image: "https://files.modtruss.com/files/steve_headshot.png",
    bio: "I am reaching 30 years of experience in project and people management, product distribution, learning from successes and more so mistakes. There are many quotes out there from many leaders of companies. I will borrow from Lee Iacocca. “Build your customer a quality product and treat them with respect.”",
  },
  {
    id: 3,
    name: "Jacob Jarosch",
    title: "Manager, Design Engineering",
    image: "https://files.modtruss.com/files/jacob_headshot.png",
    bio: "With a Mechanical Design Engineering degree from Moraine Park Technical College and previous experience in welding, press brake, and CNC machining Jacob brings invaluable knowledge of the design and manufacturing processes to ModTruss. Jacob is a CSWA and CSWP certification holder that can design and analyze parametric parts and assemblies using a variety of complex features in the SOLIDWORKS CAD software. He is an ambitious resource to our ModTruss team.",
  },
];
