import React from "react";

interface LoadingSpinnerProps {
  variant?: "primary" | "secondary" | "minimal";
  size?: "sm" | "md" | "lg";
  text?: string;
  fullScreen?: boolean;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  variant = "primary",
  size = "md",
  text,
  fullScreen,
}) => {
  // Size mappings
  const sizeClasses = {
    sm: "w-6 h-6",
    md: "w-12 h-12",
    lg: "w-16 h-16",
  };

  // Text size mappings
  const textSizes = {
    sm: "text-sm",
    md: "text-base",
    lg: "text-lg",
  };

  // Variant specific renders
  const spinnerVariants = {
    primary: (
      <div className="relative">
        <div
          className={`
          ${sizeClasses[size]}
          border-4
          border-brand-accent
          border-t-transparent
          rounded-full
          animate-spin
        `}
        />
        <div
          className={`
          ${sizeClasses[size]}
          border-4
          border-brand-accent
          border-b-transparent
          rounded-full
          animate-spin
          absolute
          top-0
          opacity-30
        `}
        />
      </div>
    ),

    secondary: (
      <div
        className={`
        ${sizeClasses[size]}
        border-4
        border-gray-200
        border-brand-accent
        rounded-full
        animate-spin
      `}
      />
    ),

    minimal: (
      <div
        className={`
        ${sizeClasses[size]}
        border-2
        border-gray-100
        border-t-gray-800
        rounded-full
        animate-spin
      `}
      />
    ),
  };

  return (
    <div className={`flex flex-col items-center justify-center gap-4 ${fullScreen ? "w-[95vw] h-screen" : "w-full h-full"}`}>
      {spinnerVariants[variant]}
      {text && (
        <p
          className={`
          ${textSizes[size]}
          text-gray-600
          font-medium
        `}
        >
          {text}
        </p>
      )}
    </div>
  );
};

export default LoadingSpinner;
