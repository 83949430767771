import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { enqueueSnackbar } from "notistack";
import api from "../Utils/api";
import { useLocation } from "react-router";

interface FileMetadata {
  fileName: string;
  originalName: string;
  fileUrl: string;
  thumbnailUrl: string | null;
  fileType: string;
  size: number;
  createdAt?: string;
  modifiedAt?: string;
}

interface FileContextType {
  files: FileMetadata[];
  isLoading: boolean;
  refetchFiles: () => Promise<void>;
  uploadFile: (file: File) => Promise<FileMetadata | undefined>;
  uploadMultipleFiles: (
    files: File[],
    onProgress?: (progress: number) => void
  ) => Promise<FileMetadata[] | undefined>;
  deleteFile: (fileName: string) => Promise<void>;
  downloadFile: (fileName: string, originalName?: string) => Promise<void>;
  getFileUrl: (fileName: string) => string;
  getThumbnailUrl: (fileName: string) => string;
  isImageFile: (fileName: string) => boolean;
  formatFileSize: (bytes: number) => string;
}

const FileContext = createContext<FileContextType | undefined>(undefined);

interface FileProviderProps {
  children: React.ReactNode;
}

export const FileProvider: React.FC<FileProviderProps> = ({ children }) => {
  const [files, setFiles] = useState<FileMetadata[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const fetchFiles = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/api/files/list");
      if (response?.status !== 200) {
        throw new Error("Failed to fetch files");
      }
      setFiles(response.data);
    } catch (error) {
      enqueueSnackbar("Failed to load files", { variant: "error" });
      setFiles([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (
      location.pathname.toLowerCase().includes("manage") ||
      (location.pathname.toLowerCase().includes("test") && files.length === 0)
    ) {
      fetchFiles();
    }
  }, [fetchFiles, location.pathname]);

  const uploadFile = useCallback(
    async (file: File): Promise<FileMetadata | undefined> => {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("file", file);

        const response = await api.post("/api/files/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 200) {
          await fetchFiles();
          enqueueSnackbar("File uploaded successfully", { variant: "success" });
          return response.data;
        }
      } catch (error) {
        enqueueSnackbar("Failed to upload file", { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    },
    [fetchFiles]
  );

  const uploadMultipleFiles = useCallback(
    async (
      files: File[],
      onProgress?: (progress: number) => void
    ): Promise<FileMetadata[] | undefined> => {
      setIsLoading(true);
      try {
        const uploads = files.map((file) => {
          const formData = new FormData();
          formData.append("file", file);

          return api.post<FileMetadata>("/api/files/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              if (onProgress && progressEvent.total) {
                const progress =
                  (progressEvent.loaded / progressEvent.total) * 100;
                onProgress(progress);
              }
            },
          });
        });

        const responses = await Promise.all(uploads);
        await fetchFiles();
        enqueueSnackbar("All files uploaded successfully", {
          variant: "success",
        });
        return responses.map((response) => response.data);
      } catch (error) {
        enqueueSnackbar("Failed to upload files", { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    },
    [fetchFiles]
  );

  const deleteFile = useCallback(
    async (fileName: string) => {
      setIsLoading(true);
      try {
        const response = await api.delete(`/api/files/${fileName}`);
        if (response.status === 200) {
          await fetchFiles();
          enqueueSnackbar("File deleted successfully", { variant: "success" });
        }
      } catch (error) {
        enqueueSnackbar("Failed to delete file", { variant: "error" });
      } finally {
        setIsLoading(false);
      }
    },
    [fetchFiles]
  );

  const downloadFile = useCallback(
    async (fileName: string, originalName?: string) => {
      try {
        const response = await api.get(`/api/files/${fileName}`, {
          responseType: "blob",
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.download = originalName || fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        enqueueSnackbar("Failed to download file", { variant: "error" });
      }
    },
    []
  );

  const getFileUrl = useCallback((fileName: string): string => {
    return `https://files.modtruss.com/files/${fileName}`;
  }, []);

  const getThumbnailUrl = useCallback((fileName: string): string => {
    return `https://files.modtruss.com/files/thumbnail/${fileName}`;
  }, []);

  const isImageFile = useCallback((fileName: string): boolean => {
    const extension = fileName.toLowerCase().split(".").pop();
    return [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "webp",
      "jfif",
      "tif",
      "tiff",
    ].includes(extension || "");
  }, []);

  const formatFileSize = useCallback((bytes: number): string => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  }, []);

  const value = {
    files,
    isLoading,
    refetchFiles: fetchFiles,
    uploadFile,
    uploadMultipleFiles,
    deleteFile,
    downloadFile,
    getFileUrl,
    getThumbnailUrl,
    isImageFile,
    formatFileSize,
  };

  return <FileContext.Provider value={value}>{children}</FileContext.Provider>;
};

export const useFiles = () => {
  const context = useContext(FileContext);
  if (context === undefined) {
    throw new Error("useFiles must be used within a FileProvider");
  }
  return context;
};
