import PageHeader from "../General/PageHeader";
import { motion } from "framer-motion";
import ResourceCard from "./ResourceCard";
import { Resource } from "../../Types/types";
import { SEO } from "../General/SEO";

export default function Resources() {
  const resources: Resource[] = [
    {
      id: 7,
      title: "ModTruss Products Catalog",
      type: "doc",
      url: "https://modtruss.com/ModTruss_Catalog.pdf",
    },
    {
      id: 3,
      title: "Connection Guide",
      type: "doc",
      url: "https://modtruss.com/ModTruss_Connection_Guide.pdf",
    },
    {
      id: 1,
      title: "ModTruss Tool Kit",
      type: "tool",
      url: "https://files.modtruss.com/files/mt-tk-01-sae_modtruss_tools_kit-1.pdf",
    },
    {
      id: 2,
      title: "Load Tables",
      type: "data",
      items: [
        {
          id: "toolkit-1",
          title: 'TRUSS - 3" STEEL LOAD TABLE',
          description: "This is a 3 inch steel truss load table",
          url: "https://files.modtruss.com/files/tech.catalog_v6_3.28.19_03_st_tr_loadtables.pdf",
        },
        {
          id: "toolkit-2",
          title: 'TRUSS - 6" ALUMINUM LOAD TABLE',
          description: "This is a 6 inch aluminum truss load table",
          url: "https://files.modtruss.com/files/tech.catalog_v6_6.13.19_06_al_tr_loadtables.pdf",
        },
        {
          id: "toolkit-2",
          title: 'TRUSS - 6" STEEL LOAD TABLE',
          description: "This is a 6 inch steel truss load table",
          url: "https://files.modtruss.com/files/tech.catalog_v6_6.13.19_06_st_tr_loadtables.pdf",
        },
        {
          id: "toolkit-2",
          title: 'TRUSS - 12" ALUMINUM LOAD TABLE',
          description: "This is a 12 inch aluminum truss load table",
          url: "https://files.modtruss.com/files/tech.catalog_v6_6.13.19_12_al_tr_loadtables.pdf",
        },
        {
          id: "toolkit-2",
          title: 'TRUSS - 12" STEEL LOAD TABLE',
          description: "This is a 12 inch steel truss load table",
          url: "https://files.modtruss.com/files/tech.catalog_v6_3.28.19_12_st_tr_loadtables.pdf",
        },
        {
          id: "toolkit-2",
          title: 'EXTRUSION - 3"X3" ALUMINUM LOAD TABLE',
          description: "This is a 3 inch extrusion load table",
          url: "https://files.modtruss.com/files/tech.catalog_v6_3.28.19_03_ex_loadtables.pdf",
        },
        {
          id: "toolkit-2",
          title: 'EXTRUSION - 6"X6" ALUMINUM LOAD TABLE',
          description: "This is a 6 inch aluminum extrusion load table",
          url: "https://files.modtruss.com/files/6_x6_-aluminum-extrusion-load-table-updated.pdf",
        },
        {
          id: "toolkit-2",
          title: 'EXTRUSION - 6"X12" ALUMINUM LOAD TABLE',
          description: "This is a 6 by 12 inch aluminum extrusion load table",
          url: "https://files.modtruss.com/files/6_x12_-aluminum-extrusion-load-table.pdf",
        },
      ],
    },
    {
      id: 4,
      title: "SketchUp 3D Warehouse",
      type: "model",
      url: "https://3dwarehouse.sketchup.com/by/ModTruss",
    },
    {
      id: 5,
      title: "Warranty",
      type: "warranty",
      url: "https://modtruss.com/warranty-and-policies",
    },
    {
      id: 6,
      title: "Financing",
      type: "finance",
      url: "https://milestonebank.com/",
    },
  ];

  return (
    <div className="min-h-screen bg-background">
      <SEO
        title={"Resources - ModTruss"}
        description={
          "View/Download each of our company resources such as Tool Kits, Load Tables, Connection Guides, Policies, and other information."
        }
        path={"/resources"}
      />
      <PageHeader title="Resources" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-10 md:py-12"
      >
        <div className="space-y-3 sm:space-y-4">
          {resources.map((resource, index) => (
            <motion.div
              key={resource.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <ResourceCard resource={resource} />
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
}
