import React from "react";
import HeroSection from "./HeroSection";
import WhyChooseSection from "./WhyChooseSection";
import FeaturedApplicationsSection from "./FeaturedApplicationsSection";
import ProductLineSection from "./ProductLineSection";
import { SEO } from "../General/SEO";

const Home: React.FC = () => {
  return (
    <div>
      <SEO
        title={"Home - ModTruss"}
        description={
          "ModTruss is a revolutionary approach to designing and building for all industries and applications"
        }
        path={"/"}
      />
      <HeroSection />
      <ProductLineSection />
      <FeaturedApplicationsSection />
      <WhyChooseSection />
    </div>
  );
};

export default Home;
