const ModTrussBolding: React.FC<{ text: string; extraBold?: boolean }> = ({
  text,
  extraBold,
}) => {
  const parts = text.split(/(ModTruss)/g);
  return (
    <>
      {parts.map((part, index) => {
        if (part === "ModTruss") {
          return (
            <span key={index}>
              Mod
              <span className={extraBold ? "font-extrabold" : "font-bold"}>
                Truss
              </span>
              {/* <sup className="text-xs">®</sup> */}
            </span>
          );
        }
        return part;
      })}
    </>
  );
};

export default ModTrussBolding;
