import { useState, useEffect } from "react";
import { ArrowUp } from "lucide-react";
import { scrollToTop } from "../../Utils/functions";

const FloatingActionButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 50) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <button
      onClick={scrollToTop}
      className={`
        fixed
        bottom-4 sm:bottom-6 md:bottom-8
        right-4 sm:right-6 md:right-8
        p-3 sm:p-3.5 md:p-4
        border
        bg-brand-accent
        hover:bg-brand-accentHover
        text-brand-accentText
        rounded-full
        shadow-lg
        transition-all
        duration-300
        ease-in-out
        flex
        items-center
        justify-center
        z-40
        ${isVisible ? "opacity-100 scale-100" : "opacity-0 scale-0"}
      `}
      aria-label="Scroll to top"
    >
      <ArrowUp className="h-5 w-5 sm:h-5.5 sm:w-5.5 md:h-6 md:w-6" />
    </button>
  );
};

export default FloatingActionButton;