import React from "react";
import {
  convertImageUrlToThumbnail,
  handleNavigation,
} from "../../Utils/functions";
import { ArrowRight } from "lucide-react";
import { leadershipInformation } from "../../Constants/leadership";
import ModTrussBolding from "../General/ModTrussBolding";

const LeadershipSection: React.FC = () => {
  return (
    <div className="bg-background py-8 sm:py-10 md:py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center mb-8 sm:mb-12 md:mb-16">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-content mb-3 sm:mb-4">
            Meet Our Leadership
          </h2>
          <p className="text-base sm:text-lg text-content-secondary max-w-3xl mx-auto">
            Our experienced team brings together decades of expertise in
            engineering, manufacturing, and event production to drive innovation
            in modular structural solutions.
          </p>
        </div>

        {/* Leadership Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8">
          {leadershipInformation.map((leader) => (
            <div
              key={leader.id}
              className="group border border-gray-200 bg-white
                transition-all duration-300 overflow-hidden hover:shadow-xl "
            >
              {/* Image Container */}
              <div className="relative h-64 sm:h-72 overflow-hidden">
                <img
                  src={convertImageUrlToThumbnail(leader.image)}
                  alt={leader.name}
                  className="w-full h-full object-contain 
                    transition-transform duration-500 group-hover:scale-105"
                />
                {/* Overlay */}
                {/* <div
                  className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent 
                    opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                /> */}
              </div>

              {/* Content */}
              <div className="p-4 sm:p-5 md:p-6">
                <div className="mb-3 sm:mb-4">
                  <h3 className="text-lg sm:text-xl font-bold text-content">
                    {leader.name}
                  </h3>
                  <p className="text-sm sm:text-base text-content font-medium">
                    {leader.title}
                  </p>
                </div>

                <p
                  className="text-sm sm:text-base text-content-secondary 
                  mb-4 sm:mb-6 line-clamp-4"
                >
                  <ModTrussBolding text={leader.bio} />
                </p>

                {/* Contact Links */}
                <div className="flex gap-3 sm:gap-4">
                  {leader.linkedin && (
                    <a
                      href={leader.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-content-secondary hover:text-brand-accent transition-colors"
                    >
                      <svg
                        className="w-4 h-4 sm:w-5 sm:h-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                      </svg>
                    </a>
                  )}
                  {leader.email && (
                    <a
                      href={`mailto:${leader.email}`}
                      className="text-content-secondary hover:text-brand-accent transition-colors"
                    >
                      <svg
                        className="w-4 h-4 sm:w-5 sm:h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center mt-6 sm:mt-8 md:mt-10">
          <button
            onClick={() => handleNavigation("/leadership")}
            className="group bg-brand-accent text-brand-accentText px-4 sm:px-6 md:px-8 
              py-3 sm:py-3.5 md:py-4 text-sm sm:text-base md:text-lg font-medium 
              transition-all duration-300 hover:bg-brand-accentHover 
              flex items-center gap-1.5 sm:gap-2 border"
          >
            MORE ABOUT OUR LEADERSHIP
            <ArrowRight
              className="w-4 h-4 sm:w-4.5 sm:h-4.5 md:w-5 md:h-5 
              transition-transform duration-300 group-hover:translate-x-1"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeadershipSection;
