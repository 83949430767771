import { NavigateFunction } from "react-router-dom";

let navigate: NavigateFunction | undefined;

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const initializeNavigation = (navigationFunction: NavigateFunction) => {
  navigate = navigationFunction;
};

export const handleNavigation = (
  path: string,
  options?: {
    replace?: boolean;
    scroll?: boolean;
    state?: any;
  }
) => {
  if (path.startsWith("https://") || path.startsWith("http://")) {
    window.location.href = path;
    return;
  }

  if (!navigate) {
    console.warn(
      "Navigation not initialized. Please call initializeNavigation first."
    );
    return;
  }

  const { replace = false, scroll = true, state = undefined } = options || {};

  navigate(path, { replace, state });

  if (scroll) {
    // Small delay to ensure navigation has started
    setTimeout(() => scrollToTop(), 0);
  }
};

export const convertTitleToUrl = (title: string): string => {
  return title.toLowerCase().replace(/ /g, "-");
};

export const convertUrlToTitle = (url: string): string => {
  return url
    .split("-")
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(" ");
};

// export const scrollToSection = (sectionId: string) => {
//   const element = document.getElementById(sectionId);
//   if (!element) return;

//   const isMobile = window.innerWidth < 768; // md breakpoint
//   const isSmall = window.innerWidth >= 640 && window.innerWidth < 768; // sm breakpoint

//   // Calculate offset based on screen size
//   let offset = 64 + 100; // 16px * 4 for base mobile
//   if (isSmall) {
//     offset = 80; // 20px * 4 for sm screens
//   } else if (!isMobile) {
//     offset = 96 + 125; // 24px * 4 for md and up
//   }

//   // Add extra offset for sticky navigation if present
//   const navMenu = document.getElementById("navigation-menu");
//   if (navMenu) {
//     offset += navMenu.offsetHeight;
//   }

//   const elementPosition = element.getBoundingClientRect().top;
//   const offsetPosition = elementPosition + window.pageYOffset - offset;

//   window.scrollTo({
//     top: offsetPosition,
//     behavior: "smooth",
//   });
// };

export const scrollToSection = (sectionId: string) => {
  const element = document.getElementById(sectionId);
  if (!element) return;

  const isMobile = window.innerWidth < 768; // md breakpoint
  const isSmall = window.innerWidth >= 640 && window.innerWidth < 768; // sm breakpoint

  // Calculate offset based on screen size
  let offset = 64; // 16px * 4 for base mobile
  if (isSmall) {
    offset = 80; // 20px * 4 for sm screens
  } else if (!isMobile) {
    offset = 96; // 24px * 4 for md and up
  }

  // Add extra offset for sticky navigation if present
  const navMenu = document.getElementById("navigation-menu");
  if (navMenu) {
    offset += navMenu.offsetHeight;
  }

  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

// Function to determine if a color is light or dark
export const isLightColor = (color: string) => {
  // Convert hex to RGB
  const hex = color.replace("#", "");
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  // Calculate relative luminance using WCAG formula
  // https://www.w3.org/TR/WCAG20/#relativeluminancedef
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5;
};

// Function to get contrasting text color
export const getContrastColor = (backgroundColor: string) => {
  return isLightColor(backgroundColor) ? "text-black" : "text-white";
};

export const convertImageUrlToThumbnail = (url: string) => {
  const urlParts = url.split("/");
  const fileName = urlParts.pop();
  if (!fileName) return url;

  const fileNameWithoutExtension = fileName.substring(
    0,
    fileName.lastIndexOf(".")
  );
  const thumbnailUrl = `${urlParts.join(
    "/"
  )}/thumbnails/${fileNameWithoutExtension}.jpg`;
  return thumbnailUrl;
};
