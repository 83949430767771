import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Home } from "lucide-react";
import { handleNavigation } from "../../Utils/functions";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "404 - Page Not Found";
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full text-center space-y-8">
        <div className="space-y-4">
          <h1 className="text-6xl font-bold text-content">404</h1>
          <h2 className="text-2xl font-semibold text-content">
            Page not found!
          </h2>
          <p className="text-gray-600">
            Sorry, we couldn't find the page you're looking for. It might have
            been moved, deleted, or never existed.
          </p>
        </div>

        <div className="space-y-4">
          <button
            onClick={() => navigate(-1)}
            className="w-full inline-flex items-center justify-center gap-2 
              px-4 py-2.5 border border-content 
              font-medium hover:bg-brand-accent text-content 
              transition-colors duration-300"
          >
            <ArrowLeft className="w-5 h-5" />
            Go Back
          </button>

          <button
            onClick={() => handleNavigation("/")}
            className="w-full inline-flex items-center justify-center gap-2 
              px-4 py-2.5 bg-brand-accent text-brand-accentText 
              font-medium hover:bg-brand-accentHover 
              transition-colors duration-300 border"
          >
            <Home className="w-5 h-5" />
            Return Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
