import React from "react";
import { Trophy } from "lucide-react";
import Logo from "../../assets/Logos/MT_Logo_Clover_Black_stacked_just_logo.png";

interface Award {
  id: number;
  title: string;
  year: string;
  organization?: string;
  description?: string;
}

const AwardsSection: React.FC = () => {
  const awards: Award[] = [
    {
      id: 1,
      title: "Best Large Booth",
      year: "2016",
      organization: "Live Design Awards (LDI)",
      description: "Awarded for the best large booth design at LDI 2016.",
    },
    {
      id: 2,
      title: "Engineering Product of The Year",
      year: "2015",
      organization: "ABTT",
      description:
        "Awarded for the best engineering product of the year in 2015.",
    },
    {
      id: 3,
      title: "Best Large Booth",
      year: "2015",
      organization: "Live Design Awards (LDI)",
      description: "Awarded for the best large booth design at LDI 2015.",
    },
    {
      id: 4,
      title: "Best New Innovative Product",
      year: "2015",
      organization: "CINE Expo",
      description:
        "Recognized as the best new innovative product at CINE Expo 2015.",
    },
    {
      id: 5,
      title: "Best Small Booth",
      year: "2014",
      organization: "Live Design Awards (LDI)",
      description: "Awarded for the best small booth design at LDI 2014.",
    },
    {
      id: 6,
      title: "Best New Rigging",
      year: "2014",
      organization: "Live Design Awards (LDI)",
      description: "Recognized for the best new rigging solution at LDI 2014.",
    },
  ];

  return (
    <div className="bg-background">
      <div
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 
        py-6 sm:py-7 md:py-8"
      >
        {/* Section Header */}
        <div className="text-center mb-8 sm:mb-12 md:mb-16">
          <div className="flex items-center justify-center gap-2 sm:gap-3 md:gap-4 mb-3 sm:mb-4">
            <div className="flex items-center gap-2 sm:gap-3 md:gap-4">
              <Trophy className="w-6 h-6 sm:w-7 sm:h-7 md:w-8 md:h-8 text-content" />
              <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-content">
                Awards & Recognition
              </h2>
            </div>
            <img
              src={Logo}
              alt="MT Logo"
              className="h-6 sm:h-7 md:h-8 w-auto"
            />
          </div>
          <p className="text-base sm:text-lg text-content-secondary max-w-3xl mx-auto">
            Our commitment to innovation and excellence has been recognized
            through various awards and patents.
          </p>
        </div>

        {/* Awards List */}
        <div className="max-w-7xl mx-auto">
          <div className="space-y-4 sm:space-y-5 md:space-y-6">
            {awards.map((award) => (
              <div
                key={award.id}
                className="group relative pl-6 sm:pl-7 md:pl-8 py-4 sm:py-5 md:py-6 
                  hover:bg-background-rowHover transition-colors rounded-lg"
              >
                {/* Accent Line */}
                <div className="absolute left-0 top-0 bottom-0 w-1 sm:w-1.5 md:w-2 bg-content" />

                {/* Content */}
                <div className="grid sm:grid-cols-[1fr,auto] gap-3 sm:gap-4 items-baseline">
                  <div>
                    <h3
                      className="text-lg sm:text-xl font-semibold text-content 
                      transition-colors"
                    >
                      {award.title}
                    </h3>
                    {award.organization && (
                      <p className="text-sm sm:text-base text-content-secondary mt-1">
                        {award.organization}
                      </p>
                    )}
                    {award.description && (
                      <p className="text-xs sm:text-sm text-content-secondary mt-2">
                        {award.description}
                      </p>
                    )}
                  </div>
                  <div className="text-left sm:text-right">
                    <span
                      className="inline-block bg-gray-100 text-content-secondary 
                      px-2 sm:px-3 py-1 rounded-full text-xs sm:text-sm font-medium 
                      group-hover:bg-content group-hover:text-white transition-all"
                    >
                      {award.year}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardsSection;
