import React from "react";
import { handleNavigation } from "../../Utils/functions";
import { Mail, Phone, ArrowRight } from "lucide-react";
import Logo from "../../assets/Logos/Old_logo.jpg";
import ModTrussBolding from "./ModTrussBolding";

interface ContactInfo {
  email: string;
  phone: string;
}

interface SocialLink {
  icon: React.ReactNode;
  url: string;
  label: string;
}

interface NavigationLink {
  label: string;
  path: string;
}

interface NavigationSection {
  title: string;
  links: NavigationLink[];
}

interface FooterConfig {
  tagline: string;
  contactInfo: ContactInfo;
  navigation: NavigationSection[];
  socialLinks?: SocialLink[];
  companyInfo: {
    logo: string;
    slogan: string;
  };
}

const footerConfig: FooterConfig = {
  tagline: "Modular Building Components for any application",
  contactInfo: {
    email: "info@modtruss.com",
    phone: "+1 844 663-8787",
  },
  navigation: [
    {
      title: "Socials",
      links: [
        {
          label: "YouTube",
          path: "https://www.youtube.com/channel/UCClamF0pAMAIrsgK3Y_8nDw",
        },
        {
          label: "FaceBook",
          path: "https://www.facebook.com/share/1CxKyTYHHC/?mibextid=LQQJ4d",
        },
        { label: "Instagram", path: "https://www.instagram.com/modtruss/" },
        {
          label: "LinkedIn",
          path: "https://www.linkedin.com/company/modtruss/",
        },
      ],
    },
    {
      title: "Resources",
      links: [
        { label: "View Resources", path: "/resources" },
        {
          label: "ModTruss Products Catalog",
          path: "https://modtruss.com/ModTruss_Catalog.pdf",
        },
        { label: "Warranty & Policies", path: "/warranty-and-policies" },
        {
          label: "SketchUp 3D Warehouse",
          path: "https://3dwarehouse.sketchup.com/by/ModTruss",
        },
      ],
    },
    {
      title: "About",
      links: [
        { label: "About Us", path: "/about" },
        { label: "Leadership", path: "/leadership" },
        { label: "Contact Us", path: "/contact-us" },
      ],
    },
  ],
  // socialLinks: [
  //   {
  //     icon: <Facebook className="w-4 h-4 sm:w-5 sm:h-5" />,
  //     url: "https://www.facebook.com/ModTruss/",
  //     label: "Facebook",
  //   },
  //   {
  //     icon: <Youtube className="w-4 h-4 sm:w-5 sm:h-5" />,
  //     url: "https://www.youtube.com/channel/UCuX6EuGOVNks7sIrcbV-V0A",
  //     label: "YouTube",
  //   },
  // ],
  companyInfo: {
    logo: Logo,
    slogan: "Made in the USA",
  },
};

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-brand text-white">
      <div className="max-w-7xl mx-auto pt-10 sm:pt-14 md:pt-16 lg:pt-20 pb-6 sm:pb-8 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-12">
          {/* Left Section */}
          <div className="md:col-span-4 space-y-6 sm:space-y-8">
            <button
              onClick={() => handleNavigation("/contact-us")}
              className="group bg-brand-accent text-brand-accentText px-4 sm:px-6 py-2.5 sm:py-3 text-sm sm:text-base 
                font-medium inline-flex items-center gap-1.5 sm:gap-2 hover:bg-brand-accentHover transition-all duration-300"
            >
              CONTACT US
              <ArrowRight className="w-3.5 h-3.5 sm:w-4 sm:h-4 transition-all duration-300 group-hover:translate-x-1" />
            </button>
            <div>
              <h3 className="text-base sm:text-lg font-medium mb-4 sm:mb-6">
                {footerConfig.tagline}
              </h3>
              <div className="space-y-3 sm:space-y-4">
                <a
                  href={`mailto:${footerConfig.contactInfo.email}`}
                  className="flex items-center gap-2 text-sm sm:text-base text-content-secondaryLight 
                    hover:text-brand-accentLight transition-colors"
                >
                  <Mail className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
                  {footerConfig.contactInfo.email}
                </a>
                <a
                  href={`tel:${footerConfig.contactInfo.phone.replace(
                    /\D/g,
                    ""
                  )}`}
                  className="flex items-center gap-2 text-sm sm:text-base text-content-secondaryLight 
                    hover:text-brand-accentLight transition-colors"
                >
                  <Phone className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
                  {footerConfig.contactInfo.phone}
                </a>
              </div>
            </div>
          </div>

          {/* Navigation Sections */}
          <div className="md:col-span-8 grid grid-cols-2 sm:grid-cols-3 gap-6 sm:gap-8">
            {footerConfig.navigation.map((section, index) => (
              <div key={index}>
                <h3 className="text-base sm:text-lg font-medium mb-4 sm:mb-6">
                  {section.title}
                </h3>
                <ul className="space-y-2 sm:space-y-4">
                  {section.links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                      <button
                        onClick={() => handleNavigation(link.path)}
                        className="text-sm sm:text-base text-gray-300 hover:text-brand-accentLight 
                          transition-colors text-left"
                      >
                        {link.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-800 mt-8 sm:mt-12 md:mt-16 pt-6 sm:pt-8">
          <div className="flex flex-col sm:flex-row gap-6 sm:justify-between sm:items-center">
            {/* Left Side */}
            <div className="flex items-center gap-4 sm:gap-6">
              <div className="p-2 sm:p-3">
                <img
                  src={footerConfig.companyInfo.logo}
                  alt="ModTruss Logo"
                  className="h-6 sm:h-8 w-auto object-contain cursor-pointer"
                  onClick={() => handleNavigation("/")}
                />
              </div>
              <span className="text-sm sm:text-base text-gray-400 font-medium">
                {footerConfig.companyInfo.slogan}
              </span>
            </div>

            {/* Right Side */}
            <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6">
              <p className="text-sm sm:text-base text-gray-400">
                <ModTrussBolding
                  text={`© ${currentYear} ModTruss Inc. All Rights Reserved.`}
                />
              </p>
              <div className="flex items-center gap-4 sm:gap-6">
                {footerConfig.socialLinks?.map((social, index) => (
                  <a
                    key={index}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-brand-accentLight transition-colors"
                    aria-label={social.label}
                  >
                    {social.icon}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
