import React, { useEffect, useState } from "react";
import { Mail, Phone, Clock, MapPin } from "lucide-react";
import PageHeader from "../General/PageHeader";
import api from "../../Utils/api";
import { SEO } from "../General/SEO";

interface ContactConfig {
  title: string;
  subjectOptions: string[];
  location: {
    address: string;
    city: string;
    state: string;
    zip: string;
    mapUrl: string;
  };
  contact: {
    phone: string;
    email: string;
    hours: string;
  };
  formFields: {
    id: string;
    label: string;
    type: string;
    required: boolean;
    placeholder?: string;
    rows?: number;
  }[];
}

const config: ContactConfig = {
  title: "We'd Like To Hear From You!",
  subjectOptions: [
    "Request a Quote",
    "Become a Reseller",
    "Connect with a Reseller",
    "General Information",
  ],
  location: {
    address: "7741 Commercial Lane",
    city: "Allenton",
    state: "WI",
    zip: "53002",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.677982958679!2d-88.3696397!3d43.473365199999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88046989459a028f%3A0x408fc8f4d81f8d25!2s7741%20Commercial%20Ln%2C%20Allenton%2C%20WI%2053002!5e1!3m2!1sen!2sus!4v1731610806955!5m2!1sen!2sus",
  },
  contact: {
    phone: "+ 1.844.663.8787",
    email: "Info@ModTruss.com",
    hours: "MON-FRI 8:00am - 5:30pm CST",
  },
  formFields: [
    {
      id: "fullName",
      label: "Full Name",
      type: "text",
      required: true,
    },
    {
      id: "companyName",
      label: "Company Name",
      type: "text",
      required: true,
    },
    {
      id: "stateCountry",
      label: "State/Country",
      type: "text",
      required: true,
    },
    {
      id: "phone",
      label: "Phone Number",
      type: "tel",
      required: true,
      placeholder: "+1(123) 456-7890",
    },
    {
      id: "email",
      label: "Your Email",
      type: "email",
      required: true,
    },
    {
      id: "subject",
      label: "Subject",
      type: "text",
      required: true,
    },
    {
      id: "message",
      label: "Please explain what you are looking for:",
      type: "textarea",
      required: false,
      rows: 4,
    },
  ],
};

const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    stateCountry: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Load the reCAPTCHA script
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=6Lce4Y0qAAAAAPZhCNnzSFymeBbgb1A958HDkIt9`;
    document.body.appendChild(script);

    // Initialize reCAPTCHA once the script loads
    script.onload = () => {
      window.grecaptcha.ready(() => {
        console.log("reCAPTCHA is ready");
      });
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus("");

    try {
      // Wait for reCAPTCHA to be ready
      await new Promise<void>((resolve) => {
        if (window.grecaptcha) {
          resolve();
        } else {
          window.grecaptcha = {
            ready: (cb: () => void) => {
              resolve();
              cb();
            },
          } as any;
        }
      });

      // console.log("Executing reCAPTCHA..."); // Debug log

      const token = await window.grecaptcha.execute(
        "6Lce4Y0qAAAAAPZhCNnzSFymeBbgb1A958HDkIt9",
        {
          action: "submit_contact",
        }
      );

      // console.log("Got token:", token.substring(0, 20) + "..."); // Debug log

      const response = await api.post("/api/contact", {
        ...formData,
        captchaToken: token,
      });

      // console.log("Server response:", response.data); // Debug log

      if (response.status === 200) {
        setFormData({
          fullName: "",
          companyName: "",
          stateCountry: "",
          phone: "",
          email: "",
          subject: "",
          message: "",
        });
        setStatus("Message sent successfully!");
      }
    } catch (error) {
      // console.error("Contact form error:", error);
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to send message";
      setStatus(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const renderFormField = (field: (typeof config.formFields)[0]) => {
    const commonClasses =
      "mt-1 w-full p-2.5 sm:p-3 border border-gray-300 text-sm sm:text-base focus:border-content focus:ring-1 focus:ring-brand-accent outline-none transition-all";

    if (field.type === "textarea") {
      return (
        <textarea
          id={field.id}
          rows={field.rows}
          required={field.required}
          placeholder={field.placeholder}
          className={commonClasses}
          value={formData[field.id as keyof typeof formData]}
          onChange={handleChange}
        />
      );
    }

    if (field.id === "subject") {
      return (
        <>
          <input
            type="text"
            id={field.id}
            required={field.required}
            list="subject-options"
            className={commonClasses}
            value={formData[field.id as keyof typeof formData]}
            onChange={handleChange}
          />
          <datalist id="subject-options">
            {config.subjectOptions.map((option) => (
              <option key={option} value={option} />
            ))}
          </datalist>
        </>
      );
    }

    return (
      <input
        type={field.type}
        id={field.id}
        required={field.required}
        placeholder={field.placeholder}
        className={commonClasses}
        value={formData[field.id as keyof typeof formData]}
        onChange={handleChange}
      />
    );
  };

  return (
    <div className="min-h-screen bg-background">
      <SEO
        title={"Contact Us - ModTruss"}
        description={
          "Reach out to use to learn more, request a quote, become a reseller, or contact a reseller."
        }
        path={"/contact-us"}
      />
      <PageHeader title={config.title} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12 md:py-16 lg:py-24">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          <div className="bg-white shadow-lg p-4 sm:p-6 md:p-8">
            <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
              <div className="space-y-3 sm:space-y-4">
                {config.formFields.map((field) => (
                  <div key={field.id}>
                    <label className="text-xs sm:text-sm font-medium text-content-secondary">
                      {field.label}{" "}
                      {field.required && (
                        <span className="text-red-500">*</span>
                      )}
                    </label>
                    {renderFormField(field)}
                  </div>
                ))}
              </div>

              {/* <ReCAPTCHA
                sitekey="6Lce4Y0qAAAAAPZhCNnzSFymeBbgb1A958HDkIt9"
                onChange={setCaptchaToken}
                className="mt-4"
              /> */}

              {status && (
                <p
                  className={`text-sm ${
                    status.includes("success")
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {status}
                </p>
              )}

              <div className="pt-2 sm:pt-4">
                <button
                  type="submit"
                  className="w-full bg-brand-accent text-brand-accentText p-3 sm:p-4 
                    text-sm sm:text-base font-medium hover:bg-brand-accentHover 
                    transition-colors flex items-center justify-center gap-2 border"
                >
                  {isSubmitting ? "SENDING..." : "SUBMIT"}
                </button>
              </div>
            </form>
          </div>
          {/* Contact Information & Map */}
          <div className="space-y-6 sm:space-y-8">
            {/* Map */}
            <div className="h-[250px] sm:h-[300px] md:h-[350px] lg:h-[400px] bg-gray-100">
              <iframe
                src={config.location.mapUrl}
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>

            {/* Contact Info */}
            <div
              className="bg-white shadow-lg p-4 sm:p-6 md:p-8 
              space-y-4 sm:space-y-6"
            >
              <div className="flex items-center gap-3 sm:gap-4 text-content-secondary">
                <MapPin className="w-4 h-4 sm:w-5 sm:h-5 text-content flex-shrink-0" />
                <div className="text-sm sm:text-base">
                  <p className="font-medium">{config.location.address}</p>
                  <p>{`${config.location.city}, ${config.location.state} ${config.location.zip}`}</p>
                </div>
              </div>

              <div className="flex items-center gap-3 sm:gap-4 text-content-secondary">
                <Phone className="w-4 h-4 sm:w-5 sm:h-5 text-content flex-shrink-0" />
                <a
                  href={`tel:${config.contact.phone}`}
                  className="text-sm sm:text-base"
                >
                  {config.contact.phone}
                </a>
              </div>

              <div className="flex items-center gap-3 sm:gap-4 text-content-secondary">
                <Mail className="w-4 h-4 sm:w-5 sm:h-5 text-content flex-shrink-0" />
                <a
                  href={`mailto:${config.contact.email}`}
                  className="text-sm sm:text-base"
                >
                  {config.contact.email}
                </a>
              </div>

              <div className="flex items-center gap-3 sm:gap-4 text-content-secondary">
                <Clock className="w-4 h-4 sm:w-5 sm:h-5 text-content flex-shrink-0" />
                <p className="text-sm sm:text-base">{config.contact.hours}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
