import { useCallback, useEffect, useRef, useState } from "react";
import { handleNavigation } from "../../Utils/functions";
import { ArrowRight, ChevronLeft, ChevronRight } from "lucide-react";
import Home_1 from "../../assets/HomePage/home_page_1.jpg";
import Home_2 from "../../assets/HomePage/home_page_2.jpg";
import Home_3 from "../../assets/HomePage/home_page_3.jpg";
import Home_4 from "../../assets/HomePage/home_page_4.jpg";

const HeroSection: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const timerRef = useRef<NodeJS.Timeout>();

  const images = [Home_1, Home_2, Home_3, Home_4];

  const startTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
  }, []);

  const handleIndicatorClick = (index: number) => {
    setCurrentImageIndex(index);
    startTimer();
  };

  const navigateSlide = (direction: "prev" | "next") => {
    if (direction === "prev") {
      setCurrentImageIndex((prev) =>
        prev === 0 ? images.length - 1 : prev - 1
      );
    } else {
      setCurrentImageIndex((prev) =>
        prev === images.length - 1 ? 0 : prev + 1
      );
    }
    startTimer();
  };

  useEffect(() => {
    startTimer();
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [startTimer]);

  return (
    <div className="relative h-screen">
      {/* Slideshow Images */}
      <div className="absolute inset-0 z-0 overflow-hidden">
        {images.map((image, index) => (
          <div
            key={image}
            className={`absolute inset-0 transition-all duration-1000 ${
              index === currentImageIndex
                ? "opacity-100 scale-100"
                : "opacity-0 scale-105"
            }`}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-full object-cover"
            />
            {/* Gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/60 to-black/80" />
          </div>
        ))}
      </div>

      {/* Navigation arrows - Hidden on mobile, visible on tablet and up */}
      <button
        onClick={() => navigateSlide("prev")}
        className="hidden sm:block absolute left-2 md:left-4 top-1/2 -translate-y-1/2 z-20 text-content-secondaryLight hover:text-white transition-colors"
        aria-label="Previous slide"
      >
        <ChevronLeft className="w-6 h-6 md:w-8 md:h-8" />
      </button>
      <button
        onClick={() => navigateSlide("next")}
        className="hidden sm:block absolute right-2 md:right-4 top-1/2 -translate-y-1/2 z-20 text-content-secondaryLight hover:text-white transition-colors"
        aria-label="Next slide"
      >
        <ChevronRight className="w-6 h-6 md:w-8 md:h-8" />
      </button>

      {/* Slideshow Indicators - Adjusted for mobile */}
      <div className="absolute bottom-24 sm:bottom-8 left-0 right-0 flex justify-center gap-1 sm:gap-2 z-20">
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => handleIndicatorClick(index)}
            className={`transition-all duration-300 rounded-none ${
              index === currentImageIndex
                ? "w-8 sm:w-12 h-1 sm:h-1.5 bg-white"
                : "w-1 sm:w-1.5 h-1 sm:h-1.5 bg-white/50 hover:bg-white/75"
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>

      {/* Content - Responsive text sizes and spacing */}
      {currentImageIndex === 0 && (
        <div
          className={`relative z-10 h-full flex items-center justify-center text-center px-4 transition-all duration-1000`}
        >
          <div className="max-w-4xl flex flex-col items-center">
            {" "}
            {/* Added flex-col and items-center */}
            <div className="mb-16 sm:mb-20">
              {" "}
              {/* Added container with margin for title */}
              <h1 className="text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 sm:mb-6 md:mb-8 leading-tight tracking-tight">
                Modular Building Components For
                <span className="block mt-1 sm:mt-2">
                  All Industries & Applications
                </span>
              </h1>
            </div>
            <div className="group inline-block">
              <button
                onClick={() => handleNavigation("/contact-us")}
                className="bg-white/10 backdrop-blur-sm text-white border-2 border-white px-4 sm:px-6 md:px-8 
          py-3 sm:py-4 text-base sm:text-lg font-medium rounded-none transition-all duration-300 
          hover:bg-white hover:text-content hover:shadow-lg hover:scale-105 
          flex items-center justify-center gap-1 sm:gap-2 group-hover:gap-4"
              >
                REQUEST A QUOTE
                <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 transition-all duration-300" />
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modern info banner - Responsive padding and text size */}
      <div className="absolute left-0 right-0 bottom-0 z-30">
        <div className="bg-brand-light backdrop-blur-md text-white px-4 sm:px-6 md:px-8 py-4 sm:py-5 md:py-6">
          <p className="text-sm sm:text-base md:text-lg font-medium text-center max-w-4xl mx-auto">
            A REVOLUTIONARY APPROACH TO DESIGNING AND BUILDING FOR ALL
            INDUSTRIES AND APPLICATIONS
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
