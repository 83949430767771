import React from "react";
import PageHeader from "../General/PageHeader";
import ProductShowcase from "./ProductShowcase";
import { SEO } from "../General/SEO";

const Products: React.FC = () => {
  return (
    <div>
      <SEO
        title={"Products - ModTruss"}
        description={
          "Explore our diverse product line designed to meet all your construction needs."
        }
        path={"/products"}
      />
      <PageHeader title="Our Products" />
      <ProductShowcase />
    </div>
  );
};

export default Products;
