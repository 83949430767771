import { createContext, useContext, useRef, useCallback, useEffect } from 'react';

interface CachedImage {
  blob: Blob;
  url: string;
}

interface ImageCacheContextType {
  getCachedImageUrl: (url: string) => Promise<string>;
  preloadImages: (urls: string[]) => Promise<void>;
  clearCache: () => void;
}

const ImageCacheContext = createContext<ImageCacheContextType | undefined>(undefined);

export const ImageCacheProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const imageCache = useRef<Map<string, CachedImage>>(new Map());

  const cleanup = useCallback(() => {
    imageCache.current.forEach((cachedImage) => {
      URL.revokeObjectURL(cachedImage.url);
    });
    imageCache.current.clear();
  }, []);

  useEffect(() => {
    return () => cleanup();
  }, [cleanup]);

  const getCachedImageUrl = useCallback(async (url: string): Promise<string> => {
    if (imageCache.current.has(url)) {
      return imageCache.current.get(url)!.url;
    }

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      
      imageCache.current.set(url, { blob, url: objectUrl });
      return objectUrl;
    } catch (error) {
      console.error('Error caching image:', error);
      return url; // Fallback to original URL
    }
  }, []);

  const preloadImages = useCallback(async (urls: string[]): Promise<void> => {
    const preloadPromises = urls
      .filter(url => !imageCache.current.has(url))
      .map(url => getCachedImageUrl(url));
    
    try {
      await Promise.all(preloadPromises);
    } catch (error) {
      console.error('Error preloading images:', error);
    }
  }, [getCachedImageUrl]);

  const clearCache = useCallback(() => {
    cleanup();
  }, [cleanup]);

  return (
    <ImageCacheContext.Provider value={{ getCachedImageUrl, preloadImages, clearCache }}>
      {children}
    </ImageCacheContext.Provider>
  );
};

export const useImageCache = () => {
  const context = useContext(ImageCacheContext);
  if (!context) {
    throw new Error('useImageCache must be used within an ImageCacheProvider');
  }
  return context;
};