import React, { useRef, useState, useEffect } from "react";
import { X, ChevronLeft, ChevronRight, ZoomIn, ZoomOut } from "lucide-react";
import { Picture } from "../../Types/types";
import { convertImageUrlToThumbnail } from "../../Utils/functions";

interface FullScreenPictureProps {
  pictures: Picture[];
  selectedIndex: number | null;
  setSelectedIndex: (index: number | null) => void;
  scale: number;
  setScale: (scale: number) => void;
  position: { x: number; y: number };
  setPosition: (position: { x: number; y: number }) => void;
}

const FullScreenPicture: React.FC<FullScreenPictureProps> = ({
  pictures,
  selectedIndex,
  setSelectedIndex,
  scale,
  setScale,
  position,
  setPosition,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isFullImageLoaded, setIsFullImageLoaded] = useState(false);
  const dragStartRef = useRef({ x: 0, y: 0 });
  const startPositionRef = useRef({ x: 0, y: 0 });
  const imageRef = useRef<HTMLImageElement>(null);
  const fullImageRef = useRef<HTMLImageElement>(null);

  // Preload next and previous images
  useEffect(() => {
    if (selectedIndex === null) return;

    const preloadImage = (url: string) => {
      const img = new Image();
      img.src = url;
    };

    // Preload next image
    const nextIndex =
      selectedIndex === pictures.length - 1 ? 0 : selectedIndex + 1;
    preloadImage(pictures[nextIndex].url);

    // Preload previous image
    const prevIndex =
      selectedIndex === 0 ? pictures.length - 1 : selectedIndex - 1;
    preloadImage(pictures[prevIndex].url);
  }, [selectedIndex, pictures]);

  // Reset full image loaded state when changing images
  useEffect(() => {
    setIsFullImageLoaded(false);
  }, [selectedIndex]);

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedIndex(null);
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  const handlePrevious = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectedIndex === null) return;
    setSelectedIndex(
      selectedIndex === 0 ? pictures.length - 1 : selectedIndex - 1
    );
    resetZoomAndPosition();
  };

  const handleNext = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectedIndex === null) return;
    setSelectedIndex(
      selectedIndex === pictures.length - 1 ? 0 : selectedIndex + 1
    );
    resetZoomAndPosition();
  };

  const resetZoomAndPosition = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleZoomIn = (e: React.MouseEvent) => {
    e.stopPropagation();
    setScale((prev: number) => Math.min(prev + 0.5, 3));
  };

  const handleZoomOut = (e: React.MouseEvent) => {
    e.stopPropagation();
    setScale((prev: number) => Math.max(prev - 0.5, 1));
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.target !== imageRef.current || scale <= 1) return;
    e.preventDefault();
    setIsDragging(true);
    dragStartRef.current = { x: e.clientX, y: e.clientY };
    startPositionRef.current = { ...position };
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || scale <= 1) return;
    const deltaX = e.clientX - dragStartRef.current.x;
    const deltaY = e.clientY - dragStartRef.current.y;
    setPosition({
      x: startPositionRef.current.x + deltaX,
      y: startPositionRef.current.y + deltaY,
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div>
      {selectedIndex !== null && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 z-50"
          onClick={handleClose}
        >
          <div
            className="fixed inset-0 flex items-center justify-center"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-white p-2 hover:bg-white/10 rounded-full transition-colors z-50"
            >
              <X size={24} />
            </button>

            <button
              onClick={handlePrevious}
              className="absolute left-4 top-1/2 -translate-y-1/2 text-white p-2 hover:bg-white/10 rounded-full transition-colors z-50"
            >
              <ChevronLeft size={24} />
            </button>

            <button
              onClick={handleNext}
              className="absolute right-4 top-1/2 -translate-y-1/2 text-white p-2 hover:bg-white/10 rounded-full transition-colors z-50"
            >
              <ChevronRight size={24} />
            </button>

            <div
              className="absolute top-4 left-4 flex gap-2 z-50"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={handleZoomIn}
                className="text-white p-2 hover:bg-white/10 rounded-full transition-colors"
                disabled={scale >= 3}
              >
                <ZoomIn size={24} />
              </button>
              <button
                onClick={handleZoomOut}
                className="text-white p-2 hover:bg-white/10 rounded-full transition-colors"
                disabled={scale <= 1}
              >
                <ZoomOut size={24} />
              </button>
            </div>

            <div
              className="relative w-full h-full flex items-center justify-center overflow-hidden"
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              {/* Thumbnail (shown while full image loads) */}
              <img
                src={convertImageUrlToThumbnail(pictures[selectedIndex].url)}
                alt={pictures[selectedIndex].title}
                className={`max-w-full max-h-full object-contain select-none transition-opacity duration-300 ${
                  isFullImageLoaded ? "opacity-0" : "opacity-100"
                }`}
                style={{
                  transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
                  position: "absolute",
                }}
              />

              {/* Full resolution image (hidden until loaded) */}
              <img
                ref={imageRef}
                src={pictures[selectedIndex].url}
                alt={pictures[selectedIndex].title}
                className={`max-w-full max-h-full object-contain select-none transition-opacity duration-300 ${
                  isFullImageLoaded ? "opacity-100" : "opacity-0"
                }`}
                style={{
                  transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
                  cursor:
                    scale > 1 ? (isDragging ? "grabbing" : "grab") : "default",
                  userSelect: "none",
                  WebkitUserSelect: "none",
                }}
                onLoad={() => setIsFullImageLoaded(true)}
                onMouseDown={handleMouseDown}
                draggable={false}
              />
            </div>

            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 text-white text-lg font-medium z-50">
              {pictures[selectedIndex].title}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FullScreenPicture;
