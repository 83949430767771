import React from "react";
import LoadingSpinner from "../General/LoadingSpinner";
import { useApplications } from "../../Providers/ApplicationProvider";
import ItemCard from "../General/ItemCard";

const ApplicationShowcase: React.FC = () => {
  const { applications, isLoading } = useApplications();

  if (isLoading) {
    return <LoadingSpinner fullScreen />;
  }

  return (
    <div className="py-8 sm:py-12 md:py-16 bg-gradient-to-b bg-background">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Title Section */}
        <div className="text-center mb-8 sm:mb-12 md:mb-16 lg:mb-20">
          <h2
            className="text-2xl sm:text-3xl md:text-4xl font-bold 
            mb-3 sm:mb-4 md:mb-6 text-content"
          >
            Common Applications Include
          </h2>
          <p
            className="text-content-secondary text-base sm:text-lg 
            max-w-2xl mx-auto px-4 sm:px-6"
          >
            All images and videos are using the same ModTruss Components just in
            different configurations
          </p>
        </div>

        <div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3
          gap-4 sm:gap-6 lg:gap-8"
        >
          {applications.map((application) => (
            <div key={application.id}>
              <ItemCard item={application} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApplicationShowcase;
