import { ProductSection as ProductSectionType } from "../../Types/types";
import ProductItem from "./ProductItem";
import { ArrowRight, Box } from "lucide-react";

interface ProductSectionProps {
  section: ProductSectionType;
}

const ProductSection: React.FC<ProductSectionProps> = ({ section }) => (
  <div className="mb-8 md:mb-12 lg:mb-14">
    <div className="text-center mb-2 md:mb-4">
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold md:mb-1">
        {section.title}
      </h2>
      <p
        className="text-content-secondary uppercase tracking-wide font-medium 
        text-sm sm:text-base sm:mb-1 md:mb-2"
      >
        {section.description}
      </p>

      <a
        href="https://3dwarehouse.sketchup.com/by/ModTruss"
        target="_blank"
        rel="noopener noreferrer"
        className="group inline-flex items-center gap-1.5 sm:gap-2 bg-white 
          px-4 sm:px-5 md:px-6 py-2.5 sm:py-3 text-sm font-medium
          hover:bg-gray-50 transition-all duration-300 border border-gray-200"
      >
        <Box className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
        SKETCHUP FILES
        <ArrowRight
          className="w-3.5 h-3.5 sm:w-4 sm:h-4 
          transition-transform duration-300 group-hover:translate-x-1"
        />
      </a>
    </div>

    <div
      className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 
      gap-4 sm:gap-6 lg:gap-8"
    >
      {section.items.map((item, index) => (
        <div key={index}>
          <ProductItem item={item} />
        </div>
      ))}
    </div>
  </div>
);

export default ProductSection;
