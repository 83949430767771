import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { enqueueSnackbar } from "notistack";
import { Product } from "../Types/types";
import api from "../Utils/api";
import { handleNavigation } from "../Utils/functions";

interface ProductContextType {
  products: Product[];
  isLoading: boolean;
  refetchProducts: () => Promise<void>;
  getProductByName: (name: string) => Product | undefined;
  getProductById: (id: string) => Promise<Product>;
  updateProduct: (productId: string, updatedProduct: Product) => Promise<void>;
  deleteProduct: (productId: string) => Promise<void>;
  createProduct: (product: Product) => Promise<void>;
}

const ProductContext = createContext<ProductContextType | undefined>(undefined);

interface ProductProviderProps {
  children: React.ReactNode;
}

export const ProductProvider: React.FC<ProductProviderProps> = ({
  children,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/api/products");
      if (response?.status !== 200) {
        throw new Error("Failed to fetch products");
      }
      const productOrder = [
        "Truss",
        "Extrusion",
        "Plates",
        "Joist Hangers",
        "Pipe Adapters",
        "Hinges",
        "Motion",
        "Rigging",
        "Access",
        "Hardware",
        "Tools",
        "Material Handling",
        "Film Tools",
      ];
      response.data.sort((a: Product, b: Product) => {
        const indexA = productOrder.indexOf(a.name);
        const indexB = productOrder.indexOf(b.name);
        if (indexA === -1 && indexB === -1) {
          return a.name.localeCompare(b.name);
        }
        if (indexA === -1) {
          return 1;
        }
        if (indexB === -1) {
          return -1;
        }
        return indexA - indexB;
      });
      setProducts(response.data);
    } catch (error) {
      enqueueSnackbar("Failed to load products", { variant: "error" });
      setProducts([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const getProductByName = useCallback(
    (name: string) =>
      products.find((p) => p.name.toLowerCase() === name.toLowerCase()),
    [products]
  );

  const getProductById = useCallback(
    async (productId: string): Promise<Product> => {
      try {
        const response = await api.get(`/api/products/${productId}`);
        if (response?.status !== 200) {
          throw new Error("Failed to fetch product");
        }
        return response.data;
      } catch (error) {
        enqueueSnackbar("Failed to fetch product", { variant: "error" });
        handleNavigation("/manage-products");
        throw error;
      }
    },
    []
  );

  const updateProduct = useCallback(
    async (productId: string, updatedProduct: Product) => {
      try {
        setIsLoading(true);
        const response = await api.put(
          `/api/products/${productId}`,
          updatedProduct
        );

        if (response.status === 200) {
          // Wait for the update to complete
          await fetchProducts();
          // Add a delay to ensure the state is fully updated
          enqueueSnackbar("Product updated successfully", {
            variant: "success",
          });
          handleNavigation("/manage-products");
        } else {
          throw new Error("Failed to update product");
        }
      } catch (error) {
        enqueueSnackbar("Failed to update product", { variant: "error" });
        await fetchProducts();
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [fetchProducts]
  );

  const deleteProduct = useCallback(
    async (productId: string) => {
      if (window.confirm("Are you sure you want to delete this product?")) {
        try {
          setIsLoading(true);
          const response = await api.delete(`/api/products/${productId}`);

          if (response.status === 204) {
            // Wait for the deletion to complete
            await fetchProducts();
            // Add a delay to ensure the state is fully updated
            enqueueSnackbar("Product deleted successfully", {
              variant: "success",
            });
            handleNavigation("/manage-products");
          } else {
            throw new Error("Failed to delete product");
          }
        } catch (error) {
          enqueueSnackbar("Failed to delete product", { variant: "error" });
          await fetchProducts();
          throw error;
        } finally {
          setIsLoading(false);
        }
      }
    },
    [fetchProducts]
  );

  const createProduct = useCallback(
    async (product: Product) => {
      try {
        setIsLoading(true);
        const response = await api.post("/api/products", product);

        if (response.status === 200) {
          // Wait for the creation to complete
          await fetchProducts();
          // Add a delay to ensure the state is fully updated
          enqueueSnackbar("Product created successfully", {
            variant: "success",
          });
          handleNavigation("/manage-products");
        } else {
          throw new Error("Failed to create product");
        }
      } catch (error) {
        enqueueSnackbar("Failed to create product", { variant: "error" });
        await fetchProducts();
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [fetchProducts]
  );

  const value = {
    products,
    isLoading,
    refetchProducts: fetchProducts,
    getProductByName,
    getProductById,
    updateProduct,
    deleteProduct,
    createProduct,
  };

  return (
    <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
  );
};

export const useProducts = () => {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error("useProducts must be used within a ProductProvider");
  }
  return context;
};
