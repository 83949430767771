import { useState } from "react";
import {
  FileText,
  Box,
  LineChart,
  Warehouse,
  Shield,
  CreditCard,
  ChevronRight,
  ChevronDown,
  ArrowUpRight,
  Download,
} from "lucide-react";
import Logo from "../../assets/Logos/MT_Logo_Clover_Black_stacked_just_logo.png";
import { Resource } from "../../Types/types";
import ModTrussBolding from "../General/ModTrussBolding";

const ResourceCard = ({ resource }: { resource: Resource }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getIcon = () => {
    const iconClass = "w-5 h-5 sm:w-6 sm:h-6";
    switch (resource.type) {
      case "tool":
        return <Box className={iconClass} />;
      case "data":
        return <LineChart className={iconClass} />;
      case "doc":
        return <FileText className={iconClass} />;
      case "model":
        return <Warehouse className={iconClass} />;
      case "warranty":
        return <Shield className={iconClass} />;
      case "finance":
        return <CreditCard className={iconClass} />;
      default:
        return <FileText className={iconClass} />;
    }
  };

  return (
    <div
      className="bg-background border-2 border-gray-300 group 
      hover:border-brand-accentHover transition-all duration-200"
    >
      <div
        onClick={() => {
          if (resource.items?.length ?? 0 > 1) {
            setIsExpanded(!isExpanded);
          } else {
            window.open(resource.url, "_blank");
          }
        }}
        className="p-4 sm:p-5 md:p-6 flex flex-col sm:flex-row sm:items-center 
          justify-between cursor-pointer transition-all duration-200 gap-4 sm:gap-0"
      >
        <div className="flex items-center gap-3 sm:gap-4">
          <div className="text-gray-400 group-hover:text-brand-accentHover transition-colors">
            {getIcon()}
          </div>
          <h3 className="text-base sm:text-lg font-semibold text-content transition-colors">
            <ModTrussBolding text={resource.title} extraBold />
          </h3>
        </div>

        <div className="flex items-center gap-2 sm:gap-3 justify-end">
          <button
            className="group/btn flex items-center gap-1.5 sm:gap-2 bg-brand-accent 
              border text-brand-accentText px-3 sm:px-4 py-2 text-sm sm:text-base
              hover:bg-brand-accentHover transition-all duration-200"
          >
            {resource.items?.length ?? 0 >= 1 ? (
              <>
                Learn More
                <ArrowUpRight
                  className="w-3.5 h-3.5 sm:w-4 sm:h-4 transition-transform duration-200 
                  group-hover/btn:translate-x-0.5 group-hover/btn:-translate-y-0.5"
                />
              </>
            ) : (
              <>
                View/Download
                <Download className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
              </>
            )}
          </button>
          {(resource.items?.length ?? 0 >= 1) && (
            <div className="text-gray-400 group-hover:text-brand-accentHover transition-colors">
              {isExpanded ? (
                <ChevronDown className="w-4 h-4 sm:w-5 sm:h-5" />
              ) : (
                <ChevronRight className="w-4 h-4 sm:w-5 sm:h-5" />
              )}
            </div>
          )}
        </div>
      </div>

      {isExpanded && resource.items && (
        <div className="border-t border-gray-100 overflow-hidden">
          {resource.items.map((item) => (
            <div
              key={item.id}
              className="transition-all duration-200 border-t hover:bg-background-rowHover"
            >
              <div
                className="flex items-start sm:items-center p-4 sm:p-5 md:p-6 
                border-b border-gray-100 last:border-0 gap-4"
              >
                {/* Logo */}
                <div className="flex-shrink-0 w-8 sm:w-12 h-8 sm:h-12 opacity-30">
                  <img
                    src={Logo}
                    alt="ModTruss Logo"
                    className="h-full w-full object-contain"
                  />
                </div>

                {/* Content and Button Container */}
                <div
                  className="flex-1 flex flex-col sm:flex-row sm:items-center 
                  sm:justify-between gap-3 sm:gap-4 min-w-0"
                >
                  {/* Content */}
                  <div className="flex-1 min-w-0">
                    <h4 className="font-semibold text-content text-sm sm:text-base">
                      {item.title}
                    </h4>
                    {item.description && (
                      <p className="mt-1 sm:mt-2 text-content-secondary text-xs sm:text-sm">
                        {item.description}
                      </p>
                    )}
                  </div>

                  {/* Download Button */}
                  {item.url && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(item.url, "_blank");
                      }}
                      className="group flex items-center gap-1.5 sm:gap-2 bg-gray-100 border 
                        text-content px-3 sm:px-4 py-2 text-sm sm:text-base
                        hover:bg-brand-accent hover:text-brand-accentText 
                        transition-all duration-200 flex-shrink-0"
                    >
                      <Download className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
                      View/Download
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ResourceCard;
