import { Settings, Grid, Package } from "lucide-react";
import ModTrussText from "../General/ModTrussText";

const solutionsAndResults = [
  {
    icon: <Package className="w-6 h-6 sm:w-7 sm:h-7 md:w-8 md:h-8" />,
    title: "Off the Shelf",
    description: "Standardized building components available for immediate deployment",
    result: "Faster Lead Times",
    resultDescription:
      "Accelerate your project timeline with readily available solutions",
  },
  {
    icon: <Grid className="w-6 h-6 sm:w-7 sm:h-7 md:w-8 md:h-8" />,
    title: "Modular and Reconfigurable",
    description: "Versatile components that can be easily adapted to various configurations",
    result: "Predictable Builds with Unlimited Possibilities",
    resultDescription:
      "Reuse products for future projects, ensuring maximum return on investment",
  },
  {
    icon: <Settings className="w-6 h-6 sm:w-7 sm:h-7 md:w-8 md:h-8" />,
    title: "Pre-Engineered",
    description: "Pre-designed components ready for immediate integration",
    result: "Faster Design Time",
    resultDescription: "Efficient assembly process with reliable timelines",
  },
];

const WhyChooseSection = () => {
  return (
    <div className="py-12 sm:py-16 md:py-20 lg:py-24 relative bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8 sm:mb-10 md:mb-12">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 sm:mb-3 md:mb-4">
            Why Choose <ModTrussText />
          </h2>
          <p className="text-base sm:text-lg text-gray-600">
            Our solutions deliver measurable results
          </p>
        </div>

        <div className="grid gap-3 sm:gap-4">
          {solutionsAndResults.map((item, index) => (
            <div
              key={index}
              className="bg-white rounded-none shadow-lg p-4 sm:p-5 md:p-6 
                transition-transform hover:scale-[1.02]"
            >
              <div className="flex flex-col md:flex-row md:items-center gap-4 sm:gap-5 md:gap-6">
                {/* Solution Section */}
                <div className="flex items-start gap-3 sm:gap-4 flex-1">
                  <div
                    className="flex-shrink-0 w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 
                    bg-content rounded-full flex items-center justify-center border"
                  >
                    <div className="text-white">{item.icon}</div>
                  </div>
                  <div>
                    <h3 className="text-lg sm:text-xl font-bold mb-1 sm:mb-2">
                      {item.title}
                    </h3>
                    <p className="text-sm sm:text-base text-gray-600">
                      {item.description}
                    </p>
                  </div>
                </div>

                {/* Divider for medium screens and up */}
                <div className="hidden md:block w-px h-16 sm:h-20 md:h-24 bg-gray-200 mx-4"></div>

                {/* Mobile divider */}
                <div className="md:hidden w-full h-px bg-gray-200 my-3 sm:my-4"></div>

                {/* Result Section */}
                <div className="flex-1">
                  <div className="flex items-start gap-3 sm:gap-4">
                    <div>
                      <h4 className="text-lg sm:text-xl font-bold text-content mb-1 sm:mb-2">
                        {item.result}
                      </h4>
                      <p className="text-sm sm:text-base text-gray-600">
                        {item.resultDescription}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* <div className="mt-10 sm:mt-12 md:mt-14 lg:mt-16">
          <TrustedBySection />
        </div> */}
      </div>
    </div>
  );
};

export default WhyChooseSection;
