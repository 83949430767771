import React from "react";
import ModTrussText from "../General/ModTrussText";
import AboutSlideshow from "./AboutSlideshow";
import ModTrussBolding from "../General/ModTrussBolding";
// import CompanyLogo from "../../assets/Logos/Logo_250x250px_Black_Stacked_17.07.2024.png";

const slides = [
  {
    image: "https://files.modtruss.com/files/about_page_photo.jpg",
    // caption: "Early Project by Kernwer",
  },
  // {
  //   image: CompanyLogo,
  //   caption: "New ModTruss Clover Logo",
  // },
];

const AboutTextSection: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 sm:pt-12 md:pt-16 pb-4 sm:pb-6 md:pb-8">
      {/* Design Build Reuse Banner */}
      <div className="w-full flex justify-center text-2xl sm:text-3xl md:text-4xl mb-6 sm:mb-8 md:mb-12">
        <span className="text-content">DESIGN</span>
        <span className="mx-2 sm:mx-3 md:mx-4 opacity-50">|</span>
        <span className="text-content">BUILD</span>
        <span className="mx-2 sm:mx-3 md:mx-4 opacity-50">|</span>
        <span className="text-content">REUSE</span>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 sm:gap-12 lg:gap-16">
        {/* Left Column */}
        <div>
          <div className="space-y-4 sm:space-y-6 md:space-y-8">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-content">
              We Are <ModTrussText />
            </h2>
            <AboutSlideshow slides={slides} />
          </div>
        </div>

        {/* Right Column - Content */}
        <div className="space-y-6 sm:space-y-8">
          <div className="space-y-4 sm:space-y-6">
            <p className="text-base sm:text-lg text-content-secondary leading-relaxed">
              <ModTrussBolding text="ModTruss founder and inventor Patrick Santini has been rooted in event production for 25+ years. Production companies Kernwer and Northern Lights, created by Santini, both became innovative leaders in temporary structures for on-site sports broadcast – Super Bowls, World Series, US Open Tennis, NASCAR, The NFL Network, and much more." />
            </p>

            <div className="py-4 sm:py-6">
              <h3 className="text-xl sm:text-2xl font-bold text-content text-center">
                <ModTrussBolding
                  text="It Was Here Where The ModTruss Product Line Was Born."
                  extraBold
                />
              </h3>
            </div>

            <p className="text-base sm:text-lg text-content-secondary leading-relaxed">
              <ModTrussBolding text="The ModTruss product line was developed, tested, and refined on the largest of stages. The structures and site locations requested by the above clients and events were demanding and ground-breaking. Traditional lighting truss and scaffolding were falling short and the ModTruss products were created to meet the challenge." />
            </p>

            <p className="text-base sm:text-lg text-content-secondary leading-relaxed">
              <ModTrussBolding text="Once ModTruss was unveiled. Other industries, besides Entertainment, came calling. From Aerospace Access and Industrial Rigging to Fall Protection and Obstacle Courses. The uses for ModTruss have proven to be endless." />
            </p>

            <p className="text-base sm:text-lg text-content-secondary leading-relaxed">
              <ModTrussBolding text="The ModTruss name is inspired from the word 'Modular' which is defined as 'designed with standardized components for easy assembly and flexible arrangement'. ModTruss is true to that definition. ModTruss first and foremost is a structural building component." />
            </p>

            <p className="text-base sm:text-lg text-content-secondary leading-relaxed">
              <ModTrussBolding text="ModTruss Inc facilitates the development, engineering, promotion, manufacturing, and sale of the product line." />
            </p>

            <p className="text-base sm:text-lg text-content-secondary leading-relaxed">
              <ModTrussBolding text="The ModTruss team is here to assist with your project needs and questions to make your visions become a reality." />
            </p>

            <p className="text-xs sm:text-sm text-content-secondary text-center italic mt-8 sm:mt-10 md:mt-12">
              (US Patent 8,418,425)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTextSection;
