import { useEffect } from "react";
import LoadingSpinner from "./LoadingSpinner";

interface ExternalRedirectProps {
  to: string;
}

const ExternalRedirect: React.FC<ExternalRedirectProps> = ({ to }) => {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);

  return (
    <div className="min-h-screen w-full flex items-center justify-center">
      <LoadingSpinner fullScreen />
    </div>
  );
};

export default ExternalRedirect;
