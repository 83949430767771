import React from "react";
import PageHeader from "../General/PageHeader";
import ApplicationShowcase from "./ApplicationShowcase";
import { SEO } from "../General/SEO";

const Applications: React.FC = () => {
  return (
    <div>
      <SEO
        title={"Applications - ModTruss"}
        description={
          "View each of our common product applications showcase and images of our work."
        }
        path={"/applications"}
      />
      <PageHeader title="Applications" />
      <ApplicationShowcase />
    </div>
  );
};

export default Applications;
