// SEO.tsx
import { Helmet } from "react-helmet";

interface SEOProps {
  title: string;
  description: string;
  path: string;
}

export const SEO = ({ title, description, path }: SEOProps) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <link rel="canonical" href={`https://modtruss.com${path}`} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={`https://modtruss.com${path}`} />
  </Helmet>
);
