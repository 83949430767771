import React from "react";
import { handleNavigation } from "../../Utils/functions";
import LoadingSpinner from "../General/LoadingSpinner";
import { ArrowRight, Download, Box } from "lucide-react";
import { useProducts } from "../../Providers/ProductProvider";
import ItemCard from "../General/ItemCard";

const ProductShowcase: React.FC = () => {
  const { products, isLoading } = useProducts();

  if (isLoading) {
    return <LoadingSpinner fullScreen />;
  }

  return (
    <div className="py-8 sm:py-12 md:py-16 bg-background">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Title Section */}
        <div className="text-center mb-8 sm:mb-12 md:mb-16">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 sm:mb-6 md:mb-8">
            Our Flagship Products
          </h2>

          <a
            href="https://3dwarehouse.sketchup.com/by/ModTruss"
            target="_blank"
            rel="noopener noreferrer"
            className="group inline-flex items-center gap-1.5 sm:gap-2 bg-white 
              px-4 sm:px-5 md:px-6 py-2.5 sm:py-3 text-sm font-medium
              hover:bg-gray-50 transition-all duration-300 border border-gray-200"
          >
            <Box className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
            SKETCHUP FILES
            <ArrowRight
              className="w-3.5 h-3.5 sm:w-4 sm:h-4 transition-transform 
              duration-300 group-hover:translate-x-1"
            />
          </a>
        </div>

        {/* Products Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 lg:gap-8 mb-10 sm:mb-12 md:mb-16 lg:mb-20">
          {products.map((product, index) => (
            <div key={index}>
              <ItemCard item={product} />
            </div>
          ))}
        </div>

        {/* Catalog Button */}
        <div className="text-center mt-10 sm:mt-16 md:mt-20">
          <button
            onClick={() => handleNavigation("/catalog")}
            className="group bg-brand-accent text-brand-accentText border 
              px-4 sm:px-6 md:px-8 py-3 sm:py-3.5 md:py-4 text-sm sm:text-base font-medium 
              hover:bg-brand-accentHover transition-all duration-300 
              inline-flex items-center gap-1.5 sm:gap-2"
          >
            <Download className="w-4 h-4 sm:w-4.5 sm:h-4.5 md:w-5 md:h-5" />
            VIEW/DOWNLOAD THE CATALOG (V5)
            <ArrowRight
              className="w-4 h-4 sm:w-4.5 sm:h-4.5 md:w-5 md:h-5 
              transition-transform duration-300 group-hover:translate-x-1"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductShowcase;
