import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from "react";
import { enqueueSnackbar } from "notistack";
import { Application } from "../Types/types";
import api from "../Utils/api";
import { handleNavigation } from "../Utils/functions";

interface ApplicationContextType {
  applications: Application[];
  isLoading: boolean;
  refetchApplications: () => Promise<void>;
  getApplicationByName: (name: string) => Application | undefined;
  getApplicationById: (id: string) => Promise<Application>;
  updateApplication: (
    applicationId: string,
    updatedApplication: Application
  ) => Promise<void>;
  deleteApplication: (applicationId: string) => Promise<void>;
  createApplication: (application: Application) => Promise<void>;
}

const ApplicationContext = createContext<ApplicationContextType | undefined>(
  undefined
);

interface ApplicationProviderProps {
  children: ReactNode;
}

export const ApplicationProvider: React.FC<ApplicationProviderProps> = ({
  children,
}) => {
  const [applications, setApplications] = useState<Application[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchApplications = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/api/applications");
      if (response?.status !== 200) {
        throw new Error("Failed to fetch applications");
      }
      const applicationOrder = [
        "Aerospace",
        "Aircraft Shoring",
        "Film, Grip, and Special Effects",
        "Theatre & Scenic",
        "Video & LED Wall Support",
        "Mezzanines & Platforms",
        "Industrial Rigging",
        "Tradeshow & Corporate Activation",
        "Television & Sports Broadcast",
        "Concert & Touring",
        "Training Simulators",
        "Obstacle Courses & Aerial Arts",
        "Other Applications",
      ];
      response.data.sort((a: Application, b: Application) => {
        const indexA = applicationOrder.indexOf(a.name);
        const indexB = applicationOrder.indexOf(b.name);
        if (indexA === -1 && indexB === -1) {
          return a.name.localeCompare(b.name);
        }
        if (indexA === -1) {
          return 1;
        }
        if (indexB === -1) {
          return -1;
        }
        return indexA - indexB;
      });
      setApplications(response.data);
    } catch (error) {
      enqueueSnackbar("Failed to load applications", { variant: "error" });
      setApplications([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchApplications();
  }, [fetchApplications]);

  const getApplicationByName = useCallback(
    (name: string) =>
      applications.find((a) => a.name.toLowerCase() === name.toLowerCase()),
    [applications]
  );

  const getApplicationById = useCallback(
    async (applicationId: string): Promise<Application> => {
      try {
        const response = await api.get(`/api/applications/${applicationId}`);
        if (response?.status !== 200) {
          throw new Error("Failed to fetch application");
        }
        return response.data;
      } catch (error) {
        enqueueSnackbar("Failed to fetch application", { variant: "error" });
        handleNavigation("/manage-applications");
        throw error;
      }
    },
    []
  );

  const updateApplication = useCallback(
    async (applicationId: string, updatedApplication: Application) => {
      try {
        setIsLoading(true);
        const response = await api.put(
          `/api/applications/${applicationId}`,
          updatedApplication
        );

        if (response.status === 200) {
          await fetchApplications();
          enqueueSnackbar("Application updated successfully", {
            variant: "success",
          });
          handleNavigation("/manage-applications");
        } else {
          throw new Error("Failed to update application");
        }
      } catch (error) {
        enqueueSnackbar("Failed to update application", { variant: "error" });
        await fetchApplications();
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [fetchApplications]
  );

  const deleteApplication = useCallback(
    async (applicationId: string) => {
      if (window.confirm("Are you sure you want to delete this application?")) {
        try {
          setIsLoading(true);
          const response = await api.delete(
            `/api/applications/${applicationId}`
          );

          if (response.status === 204) {
            await fetchApplications();
            enqueueSnackbar("Application deleted successfully", {
              variant: "success",
            });
            handleNavigation("/manage-applications");
          } else {
            throw new Error("Failed to delete application");
          }
        } catch (error) {
          enqueueSnackbar("Failed to delete application", { variant: "error" });
          await fetchApplications();
          throw error;
        } finally {
          setIsLoading(false);
        }
      }
    },
    [fetchApplications]
  );

  const createApplication = useCallback(
    async (application: Application) => {
      try {
        setIsLoading(true);
        const response = await api.post("/api/applications", application);

        if (response.status === 200) {
          await fetchApplications();
          enqueueSnackbar("Application created successfully", {
            variant: "success",
          });
          handleNavigation("/manage-applications");
        } else {
          throw new Error("Failed to create application");
        }
      } catch (error) {
        enqueueSnackbar("Failed to create application", { variant: "error" });
        await fetchApplications();
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [fetchApplications]
  );

  const value = {
    applications,
    isLoading,
    refetchApplications: fetchApplications,
    getApplicationByName,
    getApplicationById,
    updateApplication,
    deleteApplication,
    createApplication,
  };

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplications = () => {
  const context = useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error(
      "useApplications must be used within an ApplicationProvider"
    );
  }
  return context;
};
