import React from "react";
import {
  convertImageUrlToThumbnail,
  convertTitleToUrl,
  handleNavigation,
} from "../../Utils/functions";
import { ArrowRight } from "lucide-react";
import { useApplications } from "../../Providers/ApplicationProvider";
import LoadingSpinner from "../General/LoadingSpinner";

const FeaturedApplicationsSection: React.FC = () => {
  const { applications, isLoading } = useApplications();

  const showcaseApplications: string[] = [
    "Aerospace",
    "Mezzanines & Platforms",
    "Theatre & Scenic",
    "Video & LED Wall Support",
  ];

  if (isLoading) {
    return <LoadingSpinner fullScreen />;
  }

  return (
    <div className="bg-brand-light py-12 sm:py-16 md:py-20 lg:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Title Section */}
        <div className="mb-10 sm:mb-12 md:mb-16 lg:mb-20 text-center">
          <h2 className="text-white text-3xl sm:text-4xl md:text-5xl font-bold mb-4">
            Featured Applications
          </h2>
        </div>

        {/* Applications Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 lg:gap-8 mb-10 sm:mb-12 md:mb-16 lg:mb-20">
          {applications
            .filter((application) =>
              showcaseApplications.includes(application.name)
            )
            .map((app, index) => (
              <div key={index}>
                <button
                  onClick={() =>
                    handleNavigation(
                      `/applications/${convertTitleToUrl(app.name)}`
                    )
                  }
                  className="group w-full text-left"
                >
                  <div className="relative aspect-square overflow-hidden bg-gray-800">
                    <div className="w-full h-full transition-transform duration-500 group-hover:scale-105">
                      <img
                        src={convertImageUrlToThumbnail(app.thumbnail)}
                        alt={app.name}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    {/* Overlay */}
                    <div
                      className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent 
                    opacity-100 sm:opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                    />

                    {/* Learn More Text - Always visible on mobile, hover on desktop */}
                    <div
                      className="absolute bottom-0 left-0 right-0 p-4 sm:p-6 
                    transform sm:translate-y-4 sm:opacity-0 opacity-100 
                    group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300"
                    >
                      <div className="flex items-center gap-1 sm:gap-2 text-white">
                        <span className="text-sm sm:text-base">Learn More</span>
                        <ArrowRight className="w-3 h-3 sm:w-4 sm:h-4" />
                      </div>
                    </div>
                  </div>

                  {/* Title & Description */}
                  <div className="mt-3 sm:mt-4 space-y-1 sm:space-y-2">
                    <h3
                      className="text-white text-lg sm:text-xl font-medium group-hover:text-brand-accentLight 
                    transition-colors line-clamp-2"
                    >
                      {app.name}
                    </h3>
                    <p className="text-gray-400 text-xs sm:text-sm line-clamp-2">
                      {app.description}
                    </p>
                  </div>
                </button>
              </div>
            ))}
        </div>

        {/* Explore More Button */}
        <div className="flex justify-center">
          <button
            onClick={() => handleNavigation("/applications")}
            className="group bg-brand-accent text-brand-accentText px-4 sm:px-6 md:px-8 
              py-3 sm:py-4 text-base sm:text-lg font-medium hover:bg-brand-accentHover 
              transition-all duration-300 flex items-center gap-1 sm:gap-2"
          >
            EXPLORE MORE APPLICATIONS
            <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 transition-transform duration-300 group-hover:translate-x-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedApplicationsSection;
