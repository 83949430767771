import React from "react";

export type TextSection = {
  description: string;
  styles: ("bold" | "italic" | "underline")[];
  type: "header" | "paragraph";
};

interface ApplicationSectionProps {
  text: TextSection[];
}

const ApplicationSection: React.FC<ApplicationSectionProps> = ({ text }) => {
  const getStyleClasses = (styles: TextSection["styles"]) => {
    return styles
      .map((style) => {
        switch (style) {
          case "bold":
            return "font-bold";
          case "italic":
            return "italic";
          case "underline":
            return "underline";
          default:
            return "";
        }
      })
      .join(" ");
  };

  const renderText = (text: string, baseStyles: TextSection["styles"]) => {
    const parts = text.split(/(modtruss)/gi);
    return parts.map((part, index) => {
      if (part.toLowerCase() === "modtruss") {
        const [mod, truss] = ["Mod", "Truss"];
        const isCurrentlyBold = baseStyles.includes("bold");
        const trussClass = isCurrentlyBold ? "font-extrabold" : "font-bold";

        return (
          <span key={index}>
            {mod}
            <span className={trussClass}>{truss}</span>
            {/* <sup className="text-xs">®</sup> */}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div className="space-y-8">
      {text.map((section, index) => (
        <div key={index} className="max-w-7xl mx-auto">
          {section.type === "header" ? (
            <h2
              className={`text-4xl mb-6 leading-tight ${getStyleClasses(
                section.styles
              )}`}
            >
              {renderText(section.description, section.styles)}
            </h2>
          ) : (
            <p
              className={`text-lg text-gray-600 leading-relaxed ${getStyleClasses(
                section.styles
              )}`}
            >
              {renderText(section.description, section.styles)}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default ApplicationSection;
