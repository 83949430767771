import React from "react";
import { useProducts } from "../../Providers/ProductProvider";
import { ArrowRight } from "lucide-react";
import {
  convertImageUrlToThumbnail,
  convertTitleToUrl,
  getContrastColor,
  handleNavigation,
} from "../../Utils/functions";

const ProductShowcase: React.FC = () => {
  const { products, isLoading } = useProducts();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-accent" />
      </div>
    );
  }

  return (
    <section className="py-8 md:py-12 bg-background">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl md:text-4xl font-bold text-content">
            Our Product Line
          </h2>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 mb-8">
          {products.map((product, index) => (
            <div
              key={index}
              className="bg-white border rounded-lg overflow-hidden flex flex-col cursor-pointer transition-all duration-300 shadow-lg hover:shadow-xl group"
              onClick={() =>
                handleNavigation(`/products/${convertTitleToUrl(product.name)}`)
              }
            >
              <div className="relative w-full pt-[100%]">
                <div className="absolute inset-0 flex items-center justify-center p-4">
                  {product.thumbnail ? (
                    <div className="relative w-full h-full">
                      <img
                        src={convertImageUrlToThumbnail(product.thumbnail)}
                        alt={product.description}
                        className="absolute inset-0 w-full h-full object-contain group-hover:scale-105 transition-transform duration-500"
                      />
                    </div>
                  ) : (
                    <div
                      className="w-full h-full flex items-center justify-center"
                      style={{ backgroundColor: product.color }}
                    >
                      <span
                        className={`text-2xl font-bold ${getContrastColor(
                          product.color
                        )}`}
                      >
                        {product.name}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="p-4 border-t">
                <div className="flex items-center gap-3">
                  <div
                    className={`w-10 h-10 flex-shrink-0 flex items-center justify-center text-sm font-medium border
                    ${getContrastColor(product.color)}`}
                    style={{ backgroundColor: product.color }}
                  >
                    {product.abbreviation}
                  </div>
                  <h3 className="text-sm font-semibold text-content line-clamp-2">
                    {product.name}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center">
          <button
            onClick={() => handleNavigation("/products")}
            className="group bg-brand-accent text-brand-accentText px-4 mt-8 sm:px-6 md:px-8 
              py-3 sm:py-4 text-base sm:text-lg font-medium hover:bg-brand-accentHover 
              transition-all duration-300 flex items-center gap-1 sm:gap-2 border"
          >
            LEARN MORE ABOUT OUR PRODUCTS
            <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 transition-transform duration-300 group-hover:translate-x-1" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default ProductShowcase;
