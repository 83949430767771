import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useEffect, useState } from "react";

interface AboutSlideshowProps {
  slides: Array<{
    image: string;
    caption?: string;
  }>;
  interval?: number;
}

const AboutSlideshow: React.FC<AboutSlideshowProps> = ({
  slides,
  interval = 5000,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      const timer = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      }, interval);
      return () => clearInterval(timer);
    }
  }, [slides.length, interval, isHovered]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="absolute -left-4 top-0 bottom-0 w-2 bg-brand-accent" />
      <div className="relative overflow-hidden shadow-lg group aspect-[4/3] w-full">
        {/* Images Container */}
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 
              ${index === currentSlide ? "opacity-100" : "opacity-0"}
              bg-gray-100 flex items-center justify-center`}
          >
            <img
              src={slide.image}
              alt={slide.caption}
              className="max-w-full max-h-full w-auto h-auto object-contain"
              style={{ margin: "auto" }}
            />
          </div>
        ))}

        {/* Navigation Arrows */}
        {slides.length > 1 && (
          <div className="absolute inset-0 flex items-center justify-between p-4 opacity-0 group-hover:opacity-100 transition-opacity">
            <button
              onClick={prevSlide}
              className="p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <button
              onClick={nextSlide}
              className="p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
            >
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>
        )}

        {/* Dots */}
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`w-2 h-2 rounded-full transition-all
                ${
                  index === currentSlide
                    ? "bg-white w-4"
                    : "bg-white/50 hover:bg-white/75"
                }`}
            />
          ))}
        </div>
      </div>
      <p className="mt-4 text-sm text-content-secondary">
        {slides[currentSlide].caption}
      </p>
    </div>
  );
};

export default AboutSlideshow;
