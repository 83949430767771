// App.tsx
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import Navbar from "./Components/General/Navbar";
import { routes } from "./Router/routes";
import { useEffect } from "react";
import { initializeNavigation } from "./Utils/functions";
import Footer from "./Components/General/Footer";

const App: React.FC = () => {
  const element = useRoutes(routes);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    initializeNavigation(navigate);
  }, [navigate]);

  useEffect(() => {
    const formatTitle = (path: string) => {
      const parts = path.split("/");
      const lastPart = parts[parts.length - 1];
      return lastPart
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    if (
      location.pathname === "/404" ||
      location.pathname.toLowerCase().includes("manage")
    ) {
      document.title = `${
        location.pathname !== "/" ? `${formatTitle(location.pathname)} - ` : ""
      }ModTruss`;
    }
  }, [location]);

  return (
    <div className="min-h-screen">
      <Navbar />
      <main className="w-full pt-16 sm:pt-20 md:pt-24">{element}</main>
      <Footer />
    </div>
  );
};

export default App;
