import React from "react";
import { Application, Product } from "../../Types/types";
import {
  convertImageUrlToThumbnail,
  convertTitleToUrl,
  getContrastColor,
  handleNavigation,
} from "../../Utils/functions";
import { ArrowRight } from "lucide-react";

interface ItemCardProps {
  item: Product | Application;
}

const ItemCard: React.FC<ItemCardProps> = ({ item }) => {
  return (
    <div
      className="bg-white h-full border border-gray-200 shadow-lg group cursor-pointer
                transition-all duration-300 hover:shadow-xl rounded-none overflow-hidden flex flex-col"
      onClick={() => {
        if ("abbreviation" in item) {
          handleNavigation(`/products/${convertTitleToUrl(item.name)}`);
        } else {
          handleNavigation(`/applications/${convertTitleToUrl(item.name)}`);
        }
      }}
    >
      {/* Image Container - Now with fixed aspect ratio */}
      <div className="relative w-full pt-[80%] overflow-hidden border-b">
        <div className="absolute inset-0">
          <div className="w-full h-full transition-transform duration-500 group-hover:scale-105 flex items-center justify-center">
            <img
              src={convertImageUrlToThumbnail(item.thumbnail)}
              alt={item.description}
              className="max-h-full max-w-full w-auto object-contain 
            transition-transform duration-500 group-hover:scale-105"
            />
          </div>
        </div>
        {/* Overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      </div>

      {/* Content Container - Made into a flex column container */}
      <div className="p-4 sm:p-6 md:p-8 flex flex-col flex-1">
        {/* Content Wrapper - Will grow to push button to bottom */}
        <div className="flex-1">
          {/* Title with Abbreviation Box */}
          <div className="flex items-center gap-2 sm:gap-3 mb-2 sm:mb-3">
            {"abbreviation" in item && (
              <div>
                {item.abbreviation && item.color && (
                  <div
                    className={`w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center text-xs sm:text-sm font-medium border 
                                    ${getContrastColor(item.color)}`}
                    style={{ backgroundColor: item.color }}
                  >
                    {item.abbreviation}
                  </div>
                )}
              </div>
            )}
            <h3 className="text-xl sm:text-2xl font-bold transition-colors">
              {item.name}
            </h3>
          </div>

          <p className="text-content-secondary text-sm sm:text-base">
            {item.description}
          </p>
        </div>

        {/* Button Container - Now sits at bottom */}
        <div className="mt-4 sm:mt-6">
          <button className="flex items-center gap-1 sm:gap-2 text-other-learnMore text-sm sm:text-base font-medium group">
            LEARN MORE
            <ArrowRight className="w-3 h-3 sm:w-4 sm:h-4 transition-transform duration-300 group-hover:translate-x-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
