import React, { useState, useEffect } from "react";
import { Picture } from "../../Types/types";
import FullScreenPicture from "./FullScreenPicture";
import { ZoomIn } from "lucide-react";
import { convertImageUrlToThumbnail } from "../../Utils/functions";

interface PicturesGridProps {
  pictures: Picture[];
}

const PicturesGrid: React.FC<PicturesGridProps> = ({ pictures }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  if (!pictures || pictures.length === 0) return null;

  const handlePictureClick = (index: number) => {
    setSelectedIndex(index);
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  const resetZoomAndPosition = () => {
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (selectedIndex === null) return;

      switch (e.key) {
        case "ArrowLeft":
          e.preventDefault();
          setSelectedIndex((prev) =>
            prev !== null && prev === 0
              ? pictures.length - 1
              : prev !== null
              ? prev - 1
              : 0
          );
          resetZoomAndPosition();
          break;
        case "ArrowRight":
          e.preventDefault();
          setSelectedIndex((prev) =>
            prev !== null && prev === pictures.length - 1
              ? 0
              : prev !== null
              ? prev + 1
              : 0
          );
          resetZoomAndPosition();
          break;
        case "Escape":
          setSelectedIndex(null);
          resetZoomAndPosition();
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedIndex, pictures.length]);

  return (
    <div className="max-w-7xl mx-auto">
      <FullScreenPicture
        pictures={pictures}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        scale={scale}
        setScale={setScale}
        position={position}
        setPosition={setPosition}
      />

      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {pictures.map((picture, index) => (
          <div key={index} className="aspect-square w-full">
            <div
              className="group relative w-full h-full cursor-pointer bg-gray-100 rounded-md overflow-hidden"
              onClick={() => handlePictureClick(index)}
            >
              <div className="absolute inset-0">
                <img
                  src={convertImageUrlToThumbnail(picture.url)}
                  alt={picture.title}
                  className="w-full h-full object-cover"
                />
              </div>

              {/* Hover Overlay */}
              <div
                className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent 
                           opacity-0 group-hover:opacity-100 transition-all duration-300"
              >
                <div className="absolute bottom-4 left-4 right-4">
                  <h3 className="text-white font-medium truncate">
                    {picture.title}
                  </h3>
                </div>
                <div className="absolute top-4 right-4">
                  <ZoomIn className="text-white w-6 h-6 opacity-75" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PicturesGrid;
